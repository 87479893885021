import React from "react"

import { renderText } from "../utils"
import Layout from "./layout"

import {
  Header,
  Hero,
  WhyUs,
  Benefits,
  Cfa,
  Calculator,
  Footer,
  GetStarted,
} from "../slices"

const Page = ({ siteSections, nav, siteTitle, host, seoData, campaign }) => {
  return (
    <Layout nav={nav} siteTitle={siteTitle} host={host} seoData={seoData}>
      <>
        {siteSections.map(section => {
          if (section.type === "hero_section") {
            const {
              hero_section_summary,
              hero_section_title,
              hero_sign_up_success_message,
              hero_sign_up_placeholder,
              hero_sign_up_button,
              hero_document_link_button_label,
              hero_document_link,
            } = section.primary

            const boxes = section.fields.map(box => ({
              title: renderText(box.we_help_companies_box_title),
              body: renderText(box.we_help_companies_box_body),
            }))

            return (
              <>
                <Hero
                  title={renderText(hero_section_title)}
                  summary={renderText(hero_section_summary)}
                  signupSuccess={hero_sign_up_success_message}
                  signupPlaceholder={hero_sign_up_placeholder}
                  signupButton={hero_sign_up_button}
                  campaign={campaign}
                  cfaButton={{
                    label:
                      hero_document_link_button_label &&
                      renderText(hero_document_link_button_label),
                    url: hero_document_link && hero_document_link.url,
                  }}
                />
                <WhyUs boxes={boxes} id="why-us" />
              </>
            )
          }

          if (section.type === "benefits") {
            const {
              benefits_title,
              benefits_sign_up_success_message,
              benefits_sign_up_placeholder,
              benefits_sign_up_button,
              benefits_sign_up_title,
            } = section.primary
            const boxes = section.fields.map(box => ({
              heading: box.benefits_heading && renderText(box.benefits_heading),
              text: box.benefits_text && renderText(box.benefits_text),
              icon: box.benefits_icon,
            }))
            return (
              <>
                <Benefits
                  title={benefits_title && renderText(benefits_title)}
                  benefits={boxes}
                  id="benefits"
                />
                <Cfa
                  slunt
                  cfaType="benefits"
                  signupSuccess={benefits_sign_up_success_message}
                  signupPlaceholder={benefits_sign_up_placeholder}
                  signupButton={benefits_sign_up_button}
                  signupTitle={
                    benefits_sign_up_title && renderText(benefits_sign_up_title)
                  }
                  campaign={campaign}
                />
              </>
            )
          }

          if (section.type === "calculator") {
            const {
              calculator_title,
              calculator_subtitle,
              calculator_calculate_button_label,
              calculator_recalculate_button_label,
              calculator_calc_heading,
              calculator_calc_heading_image,
              calculator_individual_option_label,
              calculator_results_heading,
              calculator_limit_reached,
              calculator_results_heading_company,
              calculator_share_link_title,
              calculator_share_link_subtitle,
              calculator_share_link_button,
            } = section.primary

            const charts = section.fields.map(
              ({
                calculator_chart_title,
                calculator_chart_summary,
                calculator_chart_calculated_value_label,
                calculator_chart_comparison_value_label_one,
                calculator_chart_comparison_value_one,
                calculator_chart_comparison_value_label_two,
                calculator_chart_comparison_value_two,
                calculator_chart_id,
                calculator_chart_icon,
              }) => ({
                title: renderText(calculator_chart_title),
                summary: renderText(calculator_chart_summary),
                calculatedValueLabel:
                  calculator_chart_calculated_value_label &&
                  renderText(calculator_chart_calculated_value_label),
                comparisonValueLabelOne:
                  calculator_chart_comparison_value_label_one &&
                  renderText(calculator_chart_comparison_value_label_one),
                comparisonValueOne: calculator_chart_comparison_value_one,
                comparisonValueLabelTwo:
                  calculator_chart_comparison_value_label_two &&
                  renderText(calculator_chart_comparison_value_label_two),
                comparisonValueTwo: calculator_chart_comparison_value_two,
                id: calculator_chart_id,
                icon: calculator_chart_icon,
              })
            )

            return (
              <Calculator
                id="calculator"
                limitReachedLabel={calculator_limit_reached}
                title={calculator_title && renderText(calculator_title)}
                subtitle={
                  calculator_subtitle && renderText(calculator_subtitle)
                }
                calcTitle={
                  calculator_calc_heading && renderText(calculator_calc_heading)
                }
                calcTitleIcon={calculator_calc_heading_image}
                shareSection={{
                  title:
                    calculator_share_link_title &&
                    renderText(calculator_share_link_title),
                  subtitle:
                    calculator_share_link_subtitle &&
                    renderText(calculator_share_link_subtitle),
                  button: calculator_share_link_button,
                }}
                calculateButtonLabel={
                  calculator_calculate_button_label &&
                  renderText(calculator_calculate_button_label)
                }
                recalculateButtonLabel={
                  calculator_recalculate_button_label &&
                  renderText(calculator_recalculate_button_label)
                }
                calcIndividualOptionLabel={
                  calculator_individual_option_label &&
                  renderText(calculator_individual_option_label)
                }
                resultsHeading={
                  calculator_results_heading &&
                  renderText(calculator_results_heading)
                }
                resultsHeadingCompany={
                  calculator_results_heading_company &&
                  renderText(calculator_results_heading_company)
                }
                charts={charts}
              />
            )
          }

          if (section.type === "how_we_work") {
            const {
              how_we_work_sign_up_success_message,
              how_we_work_sign_up_placeholder,
              how_we_work_sign_up_button,
              how_we_work_sign_up_title,
              how_we_work_sign_up_subtitle,
              how_we_work_title,
            } = section.primary
            const boxes = section.fields.map(box => ({
              label:
                box.how_we_work_box_label &&
                renderText(box.how_we_work_box_label),
              title: renderText(box.how_we_work_box_title),
              body: renderText(box.how_we_work_box_body),
            }))
            return (
              <>
                <GetStarted
                  title={how_we_work_title}
                  boxes={boxes}
                  id="get-started"
                />
                <Cfa
                  id="contact-us"
                  cfaType="contactUs"
                  signupSuccess={how_we_work_sign_up_success_message}
                  signupPlaceholder={how_we_work_sign_up_placeholder}
                  signupButton={how_we_work_sign_up_button}
                  signupTitle={how_we_work_sign_up_title}
                  signupSubtitle={how_we_work_sign_up_subtitle}
                  campaign={campaign}
                />
              </>
            )
          }
        })}
      </>
    </Layout>
  )
}

export default Page
