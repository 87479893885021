import React from "react"
import { StaticQuery, graphql, Link } from "gatsby"
import { withPreview } from "@digitaliseringsbyran/gatsby-source-prismic-graphql"
import { parseMetadataFromPrismic } from "../layouts/seo"

import Page from "../layouts/homepage"

const Homepage = ({ data }) => {
  //Required check for no data being returned
  const doc = data.prismic.allHomepages.edges.slice(0, 1).pop()
  //const meta = data.site.siteMetadata;
  if (!doc) return null

  const siteSections = doc.node.body
  const nav = doc.node.header_navigation.map(
    ({
      header_navigation__label,
      header_navigation__section,
      header_navigation__type_button,
      header_navigation_link,
    }) => ({
      label: header_navigation__label,
      section: header_navigation__section,
      isButton: header_navigation__type_button,
      link: header_navigation_link,
    })
  )

  return (
    <Page
      campaign="homepage"
      siteSections={siteSections}
      nav={nav}
      siteTitle={
        doc.node.meta_title && doc.node.meta_title.length > 0
          ? doc.node.meta_title[0].text
          : null
      }
      host={data.site.siteMetadata.host}
      seoData={parseMetadataFromPrismic(doc.node, "en-gb")}
    />
  )
}

export const query = graphql`
  {
    prismic {
      allHomepages: allWebsite_homepages {
        edges {
          node {
            _meta {
              uid
              id
              type
              lang
            }
            meta_title
            meta_description
            display_name
            canonical_url
            card_description
            card_image
            card_imageSharp {
              childImageSharp {
                fixed(width: 1200, height: 630) {
                  ...GatsbyImageSharpFixed
                }
              }
            }
            card_image_square
            card_image_squareSharp {
              childImageSharp {
                fixed(width: 400, height: 400) {
                  ...GatsbyImageSharpFixed
                }
              }
            }
            card_title
            header_navigation {
              header_navigation__label
              header_navigation__section
              header_navigation__type_button
              header_navigation_link {
                _linkType
                ... on PRISMIC__FileLink {
                  url
                }
                ... on PRISMIC__Document {
                  _meta {
                    uid
                  }
                }
              }
            }
            body {
              ... on PRISMIC_Website_homepageBodyHero_section {
                type
                label
                primary {
                  hero_section_title
                  hero_section_summary
                  hero_sign_up_success_message
                  hero_sign_up_placeholder
                  hero_sign_up_button
                  hero_document_link {
                    ... on PRISMIC__FileLink {
                      _linkType
                      url
                    }
                  }
                  hero_document_link_button_label
                }
                fields {
                  we_help_companies_box_body
                  we_help_companies_box_title
                }
              }
              ... on PRISMIC_Website_homepageBodyCalculator {
                type
                label
                primary {
                  calculator_title
                  calculator_subtitle
                  calculator_calculate_button_label
                  calculator_recalculate_button_label
                  calculator_calc_heading
                  calculator_calc_heading_image
                  calculator_individual_option_label
                  calculator_results_heading
                  calculator_results_heading_company
                  calculator_limit_reached
                  calculator_share_link_title
                  calculator_share_link_subtitle
                  calculator_share_link_button
                }
                fields {
                  calculator_chart_title
                  calculator_chart_summary
                  calculator_chart_calculated_value_label
                  calculator_chart_comparison_value_label_one
                  calculator_chart_comparison_value_one
                  calculator_chart_comparison_value_label_two
                  calculator_chart_comparison_value_two
                  calculator_chart_id
                  calculator_chart_icon
                }
              }
              ... on PRISMIC_Website_homepageBodyBenefits {
                type
                label
                primary {
                  benefits_title
                  benefits_sign_up_success_message
                  benefits_sign_up_placeholder
                  benefits_sign_up_button
                  benefits_sign_up_title
                }
                fields {
                  benefits_heading
                  benefits_icon
                  benefits_text
                }
              }
              ... on PRISMIC_Website_homepageBodyHow_we_work {
                type
                label
                primary {
                  how_we_work_sign_up_success_message
                  how_we_work_sign_up_placeholder
                  how_we_work_sign_up_button
                  how_we_work_sign_up_title
                  how_we_work_sign_up_subtitle
                  how_we_work_title
                }
                fields {
                  how_we_work_box_body
                  how_we_work_box_label
                  how_we_work_box_title
                }
              }
            }
          }
        }
      }
    }
    site {
      siteMetadata {
        title
        host
      }
    }
  }
`

// Static Query does not accept variables
export default props => (
  <StaticQuery
    query={query}
    render={withPreview(
      data => (
        <Homepage data={data} {...props} />
      ),
      query
    )}
  />
)
